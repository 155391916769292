// src/api/axios.js
import axios from 'axios';

// const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1`


const BASE_URL = 'https://devbackend.rgsgroceries.com/api/v1'


const instance = axios.create({
  baseURL: BASE_URL, // Use environment variables for API base URL
  timeout: 5000, 
  headers: {
      'Authorization': `Bearer ${localStorage.getItem('authToken')}`, 
      'Content-Type': 'application/json'// Attach token directly
    }
});

// Add a request interceptor to include authentication tokens
// instance.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem('token'); // Get token from localStorage (or cookies)
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => Promise.reject(error)
// );


export const createModuleInstance = (moduleRoute) => {
  return axios.create({
    baseURL: `${BASE_URL}/${moduleRoute}`,
    timeout: 5000,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('authToken')}`, 
      'Content-Type': 'application/json'// Attach token directly
    }
  });
};

export default instance;
