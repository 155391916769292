import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk';
import headerSlice from '../features/common/headerSlice'
import modalSlice from '../features/common/modalSlice'
import rightDrawerSlice from '../features/common/rightDrawerSlice'

import productSlice from '../features/products/productSlice'
import minimalProductSlice from '../features/products/minimalProductSlice'

const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  products: productSlice,
  minimalProduct: minimalProductSlice,
}

export default configureStore({
  reducer: combinedReducer,
})