import React, { lazy, useEffect } from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { themeChange } from 'theme-change'
// import checkAuth from './app/auth';
import initializeApp from './app/init';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import dotenv from "dotenv";

// dotenv.config();
// Importing pages
const Layout = lazy(() => import('./containers/Layout'))
const Welcome = lazy(() => import('./pages/protected/Welcome'))
const Page404 = lazy(() => import('./pages/protected/404'))


// Initializing different libraries
initializeApp()


// Check for login and initialize axios
// const token = checkAuth()


function App() {

  useEffect(() => {
    // 👆 daisy UI themes initialization
    
    themeChange(false)
  }, [])


  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router>
        <Routes>
          <Route path="/" element={<Page404 />} />
          <Route path="/welcome/:storecredential" element={<Welcome />} />
          <Route path="/404" element={<Page404/>} />
         

          {/* Place new routes over this */}
          <Route  path="/app/*" element={<Layout />}  />
          {/* <Route path="*" element={<Navigate to={token ? "/app/dashboard/:storecredential" : "/404"} replace />} /> */}

        </Routes>
      </Router>
    </>
  )
}

export default App
