import axios from "axios";

const APP_URI = 'http://localhost:3001/api/v1/products'

const axiosInstance = axios.create({
    baseURL: APP_URI,
    timeout: 10000,
    headers: {
        'Content-type': 'applcation/json'
    }
});


// fetch all products
export const fetchAllProducts = async () => {
    return axiosInstance.get('/get-all');
}

// create new product
export const createNewProduct = async (productData) => {
    return axiosInstance.post('/create', productData);
}

// fetch single product
export const fetchSingleProduct = async (id) => {
    return axiosInstance.get(`/get-single/${id}`)
}

// update a product
export const updateProduct = async (id, productData) => {
    return axiosInstance.put(`/update/${id}`, productData);
}

// delete a product
export const deleteProduct = async (id) => {
    return axiosInstance.delete(`/delete/${id}`);
}
